<template>
  <laboratory-profile-component />
</template>

<script>
import LaboratoryProfileComponent from "@/components/laboratories/LaboratoryProfileComponent";

export default {
  name: "LaboratoryProfile",
  title: "Perfil del Laboratorio | Baja California Health Tourism",
  components: { LaboratoryProfileComponent },
};
</script>

<style scoped></style>
